<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, onBeforeUnmount } from 'vue'
import EmptyPart from '@common/EmptyPart'
import OfferSingle from './subComponents/OfferSingle'

// Fetch offers
const store = useStore()
store.dispatch('offer/fetchItems', { part: 'offer' })
const offers = computed(() => store.state.offer.items)
const pageStatus = computed(() => store.state.offer.pageStatus)

// Implementing intersection
const intersected = () => {
  if (pageStatus.value == 'loading') return
  store.dispatch('offer/fetchItems', { part: 'offer' })
}

// Routing for entering offers
const router = useRouter()
const enterOffer = (id) => {
  router.push({ name: 'OfferShow', params: { id } })
}

// Clear offers
onBeforeUnmount(() => store.dispatch('offer/clearItems'))
</script>

<template>
  <section class="list list--offer">
    <offer-single
      v-for="offer in offers"
      :key="offer['id']"
      :status="offer['status']"
      :old-price="offer['price']"
      :new-price="offer['price_final']"
      :image="offer['image']"
      @click="() => enterOffer(offer['id'])"
    />
  </section>
  <empty-part
    desc-top="آفری"
    desc-bottom="آفر"
    v-if="pageStatus == 'resolved' && offers.length === 0"
  />
  <failed-mode @refresh="intersected" v-if="pageStatus == 'failed'" />
  <loader v-if="pageStatus == 'loading'" />
  <observer @intersect="intersected" />
</template>

<style lang="scss" scoped>
.list--offer {
  width: 100%;
  padding: 0 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
</style>
