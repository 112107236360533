<script setup>
import {
  computed,
  onBeforeUnmount,
  ref,
  watch,
  defineProps
} from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import OfferCustomer from './subComponents/OfferCustomer'

const props = defineProps(['id', 'status'])
const isDark = computed(() => props.status !== '1')
const title = computed(() => {
  switch (props.status) {
    case '1':
      return 'فعال'

    case '2':
      return 'مصرف شده'

    case '3':
      return 'باطل شده'

    default:
      return ''
  }
})
const pageStatus = computed(() => store.state.offer.pageStatus)

// API Call
const store = useStore()
const offerItems = computed(() => store.state.offer.backupItems)
store.dispatch('offer/fetchItems', {
  part: 'offerItem',
  backup: true,
  query: { offer: props.id, status: props.status }
})

// Intersection
const intersected = () => {
  if (pageStatus.value == 'loading') return
  store.dispatch('offer/fetchItems', {
    part: 'offerItem',
    backup: true,
    query: { offer: props.id, status: props.status }
  })
}

// Handle two loadings
const firstLoad = ref(true)
watch(pageStatus, (curr) => {
  if (curr == 'resolved' || curr == 'failed') firstLoad.value = false
})

// Enter customer
const router = useRouter()
const enterCustomer = (id) => {
  router.push({ name: 'CustomerShow', params: { id } })
}

// Clean the room
onBeforeUnmount(() => store.dispatch('offer/clearBackupItems'))
</script>

<template>
  <div :class="['cmp-wrapper', isDark && 'cmp-wrapper--dark']">
    <navbar :msg="title" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading' && firstLoad"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <failed-mode v-else-if="pageStatus == 'failed'" @refresh="intersected" />
    <section class="load-resolved" v-else>
      <div class="cmp-items-wrapper">
        <offer-customer
          v-for="customer in offerItems"
          :key="customer['wallet_id']"
          :status="status"
          :name="customer['name']"
          :image="customer['image']"
          @click="() => enterCustomer(customer['wallet_id'])"
        />
      </div>
      <loader v-show="pageStatus == 'loading' && !firstLoad" />
      <observer @intersect="intersected" />
    </section>
    <fixed-footer />
  </div>
</template>

<style lang="scss" scoped>
.cmp-wrapper {
  padding-bottom: 75px;
}
.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
