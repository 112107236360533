<script setup>
import { reactive, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import useResizer from '@composable/imgResizer'
import useValidate from '@composable/offer/useValidate'
import { addOffer, addSingleOffer } from '@service/OfferService'
import BoxTextarea from '@common/BoxTextarea'
import BaseInputNumber from '@common/BaseInputNumber'
import ToggleSwitch from '@common/ToggleSwitch'

const store = useStore()
const router = useRouter()

// Check for filters must be selected
const offerOptions = computed(() => store.state.offer.offerOptions)
if (!offerOptions.value) router.push({ name: 'OfferHome' })

// Define main items
const formItems = reactive({
  offerName: '',
  offerDesc: '',
  offerImg: '',
  offerPrice: '',
  offerPriceNew: '',
  offerTime: ''
})
const modals = reactive({
  day: false
})
const days = ['امروز', ...Array.from({ length: 100 }, (_, i) => i + 1)]
const offerTablePrice = 500

// Handle img upload
const { imgBase64, imgFile, handleSelectImg } = useResizer()

// Form submition
const btnLoading = ref(false)
const isDone = ref(false)
const filters = computed(() => store.state.customer.filterData)
const offerType = computed(() => offerOptions.value?.offerType)

const submitForm = () => {
  // Prevent resubmition
  if (btnLoading.value) return

  // Validation
  const { validateOfferForm } = useValidate()
  const hasError = validateOfferForm({
    ...formItems,
    offerImage: imgBase64.value
  })
  if (hasError) return

  // API Call
  offerType.value == 'single' ? makeSingleOffer() : makeOffer()
}
const makeOffer = async () => {
  btnLoading.value = true
  try {
    await addOffer({
      ...formItems,
      offerImage: imgFile.value,
      filters: filters.value
    })
    isDone.value = true
    store.dispatch('addToast', {
      type: 'success',
      message: 'آفر شما با موفقیت ایجاد گردید.'
    })

    setTimeout(() => router.push({ name: 'OfferHome' }), 2000)
  } catch (ex) {
    if (ex.response) {
      switch (ex.response.status) {
        case 402:
          store.dispatch('profile/handleCreditModal', {
            status: 'open',
            type: 'credit'
          })
          break
        default:
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
      }
    }
  } finally {
    btnLoading.value = false
  }
}
const makeSingleOffer = async () => {
  btnLoading.value = true
  try {
    await addSingleOffer({
      ...formItems,
      offerImage: imgFile.value,
      offerWallet: offerOptions.value.customerId
    })
    isDone.value = true
    store.dispatch('addToast', {
      type: 'success',
      message: 'آفر با موفقیت ایجاد گردید.'
    })

    // store.dispatch('fetchHomeData')
    setTimeout(() => router.push({ name: 'OfferHome' }), 2000)
  } catch (ex) {
    if (ex.response) {
      switch (ex.response.status) {
        case 402:
          store.dispatch('profile/handleCreditModal', {
            status: 'open',
            type: 'credit'
          })
          break
        default:
          store.dispatch('addToast', {
            type: 'error',
            message: 'درخواست شما موفقیت‌آمیز نبود.'
          })
      }
    }
  } finally {
    btnLoading.value = false
  }
}

// Computed items
const discount = computed(() => {
  const { offerPrice, offerPriceNew } = formItems

  return offerPrice && offerPriceNew
    ? Math.abs(+offerPrice - +offerPriceNew).toLocaleString()
    : 0
})
const discountPercent = computed(() => {
  const { offerPrice, offerPriceNew } = formItems
  return offerPrice && offerPriceNew
    ? Math.abs(100 - Math.floor((+offerPriceNew / +offerPrice) * 100))
    : 0
})
const customersCount = computed(() => {
  if (offerType.value == 'single') {
    return 1
  } else if (offerType.value == 'normal') {
    return offerOptions.value.customersCount
  } else return 0
})
const finalPrice = computed(() => offerTablePrice * customersCount.value)
const btnDay = computed(() => {
  const day = formItems['offerTime']
  return day ? (day == 1 ? 'امروز' : day - 1) : 'تاریخ اعتبار'
})

// Handle offer online
const offerOnline = ref(false)
watch(offerOnline, (curr) => {
  if (curr) {
    store.dispatch('addToast', {
      type: 'error',
      message: 'پرداخت آنلاین برای شما فعال نمی‌باشد.'
    })
    setTimeout(() => (offerOnline.value = false), 500)
  }
})
</script>

<template>
  <form novalidate class="form-wrapper" @submit.prevent="submitForm">
    <template v-if="offerType === 'single'">
      <div class="filtered-customers filtered-customers--single">
        <span class="count" v-text="offerOptions?.name" />
        <h3 class="title">آماده دریافت آفر</h3>
      </div>
    </template>
    <template v-else>
      <div class="filtered-customers">
        <span
          class="count"
          v-text="
            offerOptions ? offerOptions?.customersCount?.toLocaleString() : 0
          "
        />
        <h3 class="title">نفر آماده دریافت آفر</h3>
      </div>
    </template>
    <base-input
      label="نام آفر"
      animate-type="remove"
      star-label
      v-model="formItems['offerName']"
    />
    <a class="img">
      <img class="img__img" v-if="imgBase64" :src="imgBase64" />
      <input
        class="img__input"
        type="file"
        name="file-input"
        accept="image/*"
        @change="handleSelectImg"
      />
      <div class="img__outer" v-if="!imgBase64">
        <div class="img__inner">
          <svg class="img__icon" viewBox="0 0 512 512">
            <path
              d="M446.575,0H65.425C29.349,0,0,29.35,0,65.426v381.149C0,482.65,29.349,512,65.425,512h381.15
			C482.651,512,512,482.65,512,446.574V65.426C512,29.35,482.651,0,446.575,0z M481.842,446.575
			c0,19.447-15.821,35.267-35.267,35.267H65.425c-19.447,0-35.268-15.821-35.268-35.267v-55.007l99.255-84.451
			c3.622-3.082,8.906-3.111,12.562-0.075l62.174,51.628c5.995,4.977,14.795,4.569,20.304-0.946L372.181,209.77
			c2.67-2.675,5.783-2.935,7.408-2.852c1.62,0.083,4.695,0.661,7.078,3.596l95.176,117.19V446.575z M481.842,279.865l-71.766-88.366
			c-7.117-8.764-17.666-14.122-28.942-14.701c-11.268-0.57-22.317,3.672-30.294,11.662L212.832,326.681l-51.59-42.839
			c-14.959-12.422-36.563-12.293-51.373,0.308l-79.712,67.822V65.426c0-19.447,15.821-35.268,35.268-35.268h381.15
			c19.447,0,35.267,15.821,35.267,35.268V279.865z"
            />
            <path
              d="M161.174,62.995c-40.095,0-72.713,32.62-72.713,72.713c0,40.094,32.619,72.713,72.713,72.713s72.713-32.619,72.713-72.713
			S201.269,62.995,161.174,62.995z M161.174,178.264c-23.466,0-42.556-19.091-42.556-42.556c0-23.466,19.09-42.556,42.556-42.556
			c23.466,0,42.556,19.091,42.556,42.556S184.64,178.264,161.174,178.264z"
            />
          </svg>
        </div>
      </div>
    </a>
    <!-- TEST INPUT -->
    <base-input-number
      label="قیمت اصلی"
      v-model="formItems['offerPrice']"
      star
      rial
    />
    <base-input-number
      label="قیمت جدید"
      rial
      v-model="formItems['offerPriceNew']"
      star
      style="margin-top: 15px"
    />
    <div class="calculated">
      <span>تخفیف</span>
      <h6 class="calculated__price">{{ discount }}</h6>
      <h5 class="calculated__percent">{{ discountPercent }}%</h5>
    </div>
    <div class="bta-wrapper">
      <box-textarea
        label="توضیحات"
        width="100%"
        animateType="remove"
        v-model="formItems['offerDesc']"
      />
    </div>
    <div class="credit-buttons">
      <a class="button button--form" @click="modals['day'] = true">
        <span :class="['button__text', !!formItems['offerTime'] && 'active']">
          {{ btnDay }}
        </span>
        <span class="button__text active" v-show="formItems['offerTime'] > 1">
          روز
        </span>
      </a>
      <div class="offer__online">
        <h4>
          پرداخت آنلاین
        </h4>
        <toggle-switch v-model="offerOnline" />
      </div>
      <!-- <div class="table table--form">
        <div class="table__row">
          <h6 class="table__cell cell--title">
            تعداد مشتری
          </h6>
          <h6 class="table__cell cell--amount">
            {{ customersCount.toLocaleString() }}
          </h6>
        </div>
        <i class="table__hr" />
        <div class="table__row">
          <h6 class="table__cell cell--title">
            ارسال هر آفر
          </h6>
          <h6 class="table__cell cell--price ">
            {{ offerTablePrice.toLocaleString() }}
          </h6>
        </div>
        <i class="table__hr" />
        <div class="table__row">
          <h6 class="table__cell cell--title">
            هزینه کل
          </h6>
          <h6 class="table__cell cell--price ">
            {{ finalPrice.toLocaleString() }}
          </h6>
        </div>
      </div> -->
      <button type="submit" :disabled="isDone" class="button button--black">
        <span class="button__text">ایجاد</span>
        <spinner v-if="btnLoading" />
      </button>
    </div>
    <modal
      type="center"
      title="تاریخ اعتبار"
      :is-open="modals['day']"
      @modalClosed="modals['day'] = false"
    >
      <ul class="items-wrapper">
        <li v-for="(day, idx) in days" :key="day">
          <a
            :class="{
              active: formItems['offerTime'] === day + 1
            }"
            @click="
              () => {
                if (idx === 0) formItems['offerTime'] = 1
                else formItems['offerTime'] = day + 1
                modals['day'] = false
              }
            "
          >
            {{ day }} <span v-if="idx > 0">روز</span>
          </a>
        </li>
      </ul>
    </modal>
  </form>
</template>

<style lang="scss" scoped>
.form-wrapper {
  background: linear-gradient(to bottom, #eee 0, #fff 40px);
}
.img {
  margin: 15px 0;
  overflow: hidden;
  position: relative;
  width: 90%;
  max-width: 800px;
  color: white;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
}
.img__img {
  width: 100%;
}
.img__outer {
  // use to set height
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.img__inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  border-radius: 100%;
  background-color: #ddd;
  pointer-events: none;
}
.img__icon {
  width: 40px;
  height: 40px;
  fill: #fff;
  pointer-events: none;
}
.img__text {
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  color: #fff;
}
.img__input {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
}

// calculated percent
.calculated {
  padding: 10px 18px;
  width: 90%;
  margin: 20px 0 30px;
  display: grid;
  background: linear-gradient(to left, rgba(#c69d4e, 0.2) 0, #eee 100%);
  border-radius: 15px;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}
.calculated__percent {
  margin-top: 5px;
  font-size: 40px;
  color: black;
  text-align-last: left;
  position: relative;
  text-align: center;
  width: 100%;
}
.calculated__price {
  margin-top: 5px;
  font-size: 18px;
  color: black;
  position: relative;
  text-align: center;
  // left: 12px;
  &::after {
    content: 'R';
    position: relative;
    font-size: 12px;
    top: -4px;
    right: -1px;
  }
}

.bta-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 5%;
  .bta-title {
    width: 100%;
    text-align: right;
    font-size: 15px;
    color: #b4b4b4;
    margin-bottom: 5px;
    &::before {
      position: relative;
      content: '*';
      color: #c69d4e;
      font-size: 20px;
      top: 6px;
      margin-left: 3px;
    }
  }
}

.offer__online {
  margin-bottom: 20px;
  width: 90%;
  border-radius: 15px;
  background-color: #e4e4e480;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 18px 15px 10px;
  h4 {
    font-size: 16px;
    margin: 0;
  }
}

// change in common css
.credit-buttons {
  flex-direction: column;
  margin-bottom: 30px;
}
.button--form {
  margin-bottom: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}
</style>
