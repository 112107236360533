<template>
  <label class="switch">
    <input
      type="checkbox"
      @change="$emit('update:modelValue', $event.target.checked)"
      :checked="modelValue"
    />
    <span class="toggle"></span>
  </label>
</template>

<script>
export default {
  name: 'ToggleSwitch',
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
label.switch {
  position: relative;
  width: 92px;
  height: 40px;
  cursor: pointer;
  margin: 0;
  transform: scale(0.8);
}

input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle {
  position: absolute;
  width: 92px;
  height: 100%;
  left: 0;
  top: 0;
  background: #777;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  transition: all 0.3s;
}

.toggle:before {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 30px;
  height: 30px;
  background: #efeeee;
  top: 5px;
  left: 5px;
  transition: left 0.2s;
  box-shadow: -2px 2px 4px 1px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

input:checked ~ .toggle {
  background: #c69d4e;
}

input:checked ~ .toggle:before {
  left: 57px;
}
</style>
