<script setup>
import { computed, reactive, ref, defineProps } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getCustomerOffer } from '@service/CustomerService'
import OfferItemSingle from './subComponents/OfferItemSingle'
import BaseInputNumber from '@common/BaseInputNumber'

const props = defineProps(['mobile'])
const store = useStore()
const router = useRouter()
const customer = reactive({
  name: '',
  image: ''
})
const offerItems = ref(null)
const resolved = computed(() => !!offerItems.value)
const flip = ref(false)

const getOfferInfo = async () => {
  try {
    const { data } = await getCustomerOffer({ mobile: props.mobile })
    offerItems.value = data.offers
    if (offerItems.value.length === 0) {
      store.dispatch('addToast', {
        type: 'error',
        message: 'آفر فعالی موجود نمی‌باشد.'
      })
      return router.push({ name: 'Home' })
    }
    customer['name'] = data.name
    customer['image'] = data.image
  } catch (ex) {
    console.log(ex)
    store.dispatch('addToast', {
      type: 'error',
      message: 'مشکلی در ارتباط با سرور پیش آمده است.'
    })
  }
}
getOfferInfo()

const modals = reactive({
  credit: false
})
const inputNumber = ref(null)
const focusOnInput = () => {
  inputNumber.value.$el.focus()
  // nextTick(() => {
  // window.scrollTo({ top: 300 })
  // })
}
</script>

<template>
  <div class="cmp-wrapper">
    <navbar msg="آفرهای فعال" />
    <section class="load-pending load-pending--show" v-if="!resolved">
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else>
      <div class="customer">
        <div class="customer-info">
          <div class="customer-img">
            <img :src="customer['image']" v-show="flip" @load="flip = !flip" />
            <svg
              v-if="!flip"
              style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
              viewBox="0 0 512 512"
            >
              <circle fill="#606062" cx="256" cy="256" r="256" />
              <path
                fill="#373435"
                d="M201.7 317.66c0,-7.43 -5.22,-13.42 -8.58,-18.06l-11.06 -14.74c-3.33,-4.39 -6.88,-10.74 -9.9,-15.86 -18.6,-31.45 -26.08,-64.92 -23.63,-104.73 1.99,-32.33 19.08,-65.1 47.19,-82.47 52.07,-32.16 134.88,-19.01 160.12,46.69 17.89,46.58 8.74,98.98 -15.73,141.53 -9.12,15.85 -29.58,32.83 -28.81,52.12 0.34,8.53 4.38,12 10.35,15.84 13.28,8.56 50.2,19.39 74,33.64 9.62,5.76 23.16,13.75 31.83,21.68 17.72,16.19 19.6,26.57 22.27,30.03 38.79,-44.88 62.25,-103.36 62.25,-167.33 0,-141.38 -114.62,-256 -256,-256 -141.39,0 -256,114.62 -256,256 0,64.18 23.62,122.85 62.65,167.78 1.67,-3.25 2.68,-6.15 4.75,-9.45 2.22,-3.53 4.12,-5.95 6.62,-8.84 18.93,-21.98 65.14,-46.57 93.07,-59.06 17.47,-7.81 34.61,-9.68 34.61,-28.77z"
              />
            </svg>
          </div>
          <div class="customer-title">
            <h2>
              <span style="color: #858585"
                >{{ customer['gender'] == 1 ? 'آقای' : 'خانم' }}
              </span>
              {{ customer['name'] }}
            </h2>
          </div>
        </div>
        <div class="customer-credit">
          <h3 class="cutomer-credit-title">
            موجودی اعتبار:
          </h3>
          <h4 class="customer-credit-number">
            {{ Number(300000).toLocaleString() }}
          </h4>
        </div>
        <div class="credit-buttons" style="width: 96%;">
          <a
            class="button button--black"
            @click="
              () => {
                modals['credit'] = true
                $nextTick(focusOnInput)
              }
            "
          >
            <span class="button__text" style="color: #c69d4e"
              >دریافت اعتبار</span
            >
          </a>
        </div>
      </div>
      <div class="offers-items" v-if="offerItems">
        <offer-item-single
          v-for="item in offerItems"
          :key="item.id"
          :id="item.id"
          :title="item.offer['title']"
          :price-old="item.offer['price']"
          :price-new="item.offer['price_final']"
          :create="item.offer['created_at']"
          :expire="item.offer['expire_at']"
          :image="item.offer['image']"
          :desc="item.offer['desc']"
          @offerreceived="getOfferInfo"
        />
      </div>
    </section>
    <modal
      :is-open="modals['credit']"
      @modalClosed="modals['credit'] = false"
      height="158px"
    >
      <form novalidate class="pay" @submit.prevent="submitForm">
        <base-input-number v-model="price" ref="inputNumber" />
        <div class="credit-buttons" style="width: 90%; margin: 20px 0 0;">
          <button class="button button--black">
            <span class="button__text" style="color: #c69d4e"
              >دریافت اعتبار</span
            >
            <spinner v-if="false" />
          </button>
        </div>
      </form>
    </modal>
    <fixed-footer />
  </div>
</template>

<style scoped lang="scss">
.customer {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 60px 0 0;
  margin-bottom: 20px;

  .customer-info {
    width: 100%;
    padding: 15px 0 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .customer-img {
      padding: 0;
      img {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        filter: saturate(0.3);
        box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
      }
      svg {
        border-radius: 50%;
        width: 80px;
        height: 80px;
        box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.1);
      }
    }
    .customer-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h2 {
        color: black;
        margin: 15px 0 0;
        text-align: right;
        font-size: 16px;
      }
      h3 {
        color: #777;
        margin: 0 0 8px;
        text-align: right;
        font-size: 16px;
      }
    }
  }
  .customer-credit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  .customer-credit-number {
    margin-right: 10px;
    &::after {
      content: 'ریال';
      // font-size: 12px;
      position: relative;
      // top: -3px;
      margin-right: 5px;
    }
  }
}
.offers-items {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  // margin-top: -25px;
}

.pay {
  width: 100%;
  padding: 20px 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(to top, #bbb 0, #fff 55px);
}
.pay__title {
  font-size: 18px;
  margin-bottom: 15px;
}

// change in common css
.cmp-wrapper {
  padding: 0 0 75px;
  background-color: #fff;
}
.credit-buttons {
  margin-top: 5px;
}
.credit-buttons .button {
  width: 100%;
}
.load-pending--show {
  padding-top: 60px;
  height: calc(100vh - 75px);
}
</style>
