<script setup>
import {
  computed,
  onBeforeUnmount,
  reactive,
  ref,
  defineProps
} from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { disableOffer } from '@service/OfferService'
import { localizeDate, calcExpireTime, applyTimeZone } from '@util/Time'
import CountDown from '@common/CountDown'
import FiltersTable from '@common/FiltersTable'

const props = defineProps(['id'])
// Fetch offer
const flip = ref(false)
const store = useStore()
const pageStatus = computed(() => store.state.offer.pageStatus)
const isDark = computed(() => store.state.offer.isDark)
const offer = computed(() => store.state.offer.item)
const offerType = computed(() => {
  if (!offer.value) return null
  return offer.value['filters']['creation_type'] === '1' ? 'typical' : 'single'
})

// Initial API call
const getOffer = () => {
  store.dispatch('offer/fetchItem', { part: 'offer', id: props.id })
  store.dispatch('offer/fetchOfferItemsCount', props.id)
}
getOffer()

// Disable Offer
const modals = reactive({
  confirm: false
})
const btnLoading = ref(false)
const handleDisableOffer = async () => {
  if (btnLoading.value) return

  btnLoading.value = true
  try {
    await disableOffer(props.id)
    flip.value = false
    getOffer()
    store.dispatch('addToast', {
      type: 'success',
      message: 'آفر با موفقیت ابطال گردید.'
    })
    setTimeout(() => router.push({ name: 'OfferHome' }), 2000)
  } catch (ex) {
    if (ex.response) {
      store.dispatch('addToast', {
        type: 'error',
        message: 'درخواست شما موفقیت‌آمیز نبود.'
      })
    }
  } finally {
    btnLoading.value = false
    modals['confirm'] = false
  }
}

// Enter offer item
const router = useRouter()
const offersCount = computed(() => store.state.offer.offerItemsCount)
const enterOfferItems = (child) => {
  switch (child) {
    case 1: {
      const status = isDark.value ? '2' : '1'
      if (offersCount.value[status] === 0)
        return store.dispatch('addToast', {
          type: 'error',
          message: 'داده‌ای برای نمایش موجود نیست.'
        })
      router.push({
        name: 'OfferStatus',
        params: { id: props.id, status }
      })
      break
    }
    case 2: {
      const status = isDark.value ? '3' : '2'
      if (offersCount.value[status] === 0)
        return store.dispatch('addToast', {
          type: 'error',
          message: 'داده‌ای برای نمایش موجود نیست.'
        })
      router.push({
        name: 'OfferStatus',
        params: { id: props.id, status }
      })
      break
    }
    default:
      break
  }
}

const diffDay = computed(() => {
  return calcExpireTime(offer.value['created_at'], offer.value['expire_at'])
})

// Clear offer
onBeforeUnmount(() => store.dispatch('offer/clearItem'))
</script>

<template>
  <div :class="['cmp-wrapper', isDark && 'wrapper--dark']">
    <navbar msg="آفر" />
    <section
      class="load-pending load-pending--show"
      v-if="pageStatus == 'loading'"
    >
      <img src="@img/vipon-loading.svg" />
    </section>
    <section class="load-resolved" v-else-if="pageStatus == 'resolved'">
      <div :class="['offer', isDark && 'offer--dark']">
        <h1 class="offer__title" v-text="offer ? offer['title'] : '...'" />
        <section class="offer__info">
          <figure class="offer__img">
            <img :src="offer['image']" v-show="flip" @load="flip = true" />
            <img src="@img/preload-logo.svg" v-if="!flip" />
          </figure>
          <div class="offer__prices">
            <div class="offer__pricePart">
              <h3
                class="offer__price price--old"
                v-text="offer && offer['price'].toLocaleString()"
              />
              <h3
                class="offer__price price--new"
                v-text="offer && offer['price_final'].toLocaleString()"
              />
            </div>
            <i class="offer__vr" />
            <div class="offer__pricePart">
              <h4 class="offer__off">OFF</h4>
              <h3
                class="offer__percent"
                v-text="
                  offer &&
                    100 -
                      Math.floor((offer['price_final'] / offer['price']) * 100)
                "
              />
            </div>
          </div>
          <div class="offer__circles-col">
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
            <i class="offer__circle" />
          </div>
        </section>
        <p class="offer__desc" v-text="offer ? offer['desc'] : '...'" />
      </div>
      <div :class="['time-wrapper', isDark && 'time--dark']">
        <p v-if="diffDay > 1">
          از
          <span>{{ localizeDate(offer['created_at']) }}</span>
          تا
          <span v-text="diffDay - 1" />
          روز
        </p>
        <p v-else>
          <span>{{ localizeDate(offer['created_at']) }}</span>
        </p>
        <count-down
          v-if="offer"
          :endDate="applyTimeZone(offer['expire_at'])"
          :permission="!isDark"
          class="countdown"
        />
        <div class="offBtn" v-if="!isDark" @click="modals['confirm'] = true">
          <svg class="offBtn__svg" viewBox="0 0 512 512" v-show="!btnLoading">
            <path
              d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
            />
            <path
              d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
            />
          </svg>
        </div>
        <div class="offBtn offBtn--dark" v-if="isDark">
          <svg
            class="offBtn__svg"
            width="512px"
            height="512px"
            viewBox="0 0 512 512"
            v-show="!btnLoading"
          >
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <g id="_1446895942480">
                <path
                  d="M311.36 76.79c-7.29,-2.13 -14.94,2.05 -17.07,9.34 -2.14,7.29 2.05,14.94 9.34,17.07 69.94,20.47 121.59,85.18 121.59,162.43 0,93.31 -75.91,169.22 -169.22,169.22 -93.31,0 -169.22,-75.91 -169.22,-169.22 0,-77.09 51.49,-141.91 121.59,-162.43 7.29,-2.13 11.47,-9.78 9.34,-17.07 -2.14,-7.29 -9.78,-11.47 -17.07,-9.34 -80.65,23.61 -141.38,98.33 -141.38,188.84 0,108.74 87.99,196.74 196.74,196.74 108.74,0 196.74,-87.99 196.74,-196.74 0,-89.92 -60.15,-165.06 -141.38,-188.84z"
                />
                <path
                  d="M256 49.63c-7.6,0 -13.76,6.16 -13.76,13.76l0 129.83c0,7.6 6.16,13.76 13.76,13.76 7.6,0 13.76,-6.16 13.76,-13.76l0 -129.83c0,-7.6 -6.16,-13.76 -13.76,-13.76z"
                />
              </g>
            </g>
          </svg>
          <!-- #TODO backend must add cancelled at -->
          <span class="offBtn__text">{{
            localizeDate(offer['expire_at'])
          }}</span>
        </div>
      </div>
      <div :class="['tab', isDark && 'tab--dark']">
        <div class="tab__part" @click="enterOfferItems(1)">
          <h6 class="tab__count">
            {{ isDark ? offersCount['2'] : offersCount['1'] }}
          </h6>
          <span class="tab__status"
            >آفر {{ isDark ? 'مصرف شده' : 'فعال' }}</span
          >
        </div>
        <div class="tab__part" @click="enterOfferItems(2)">
          <h6 class="tab__count">
            {{ isDark ? offersCount['3'] : offersCount['2'] }}
          </h6>
          <span class="tab__status"
            >آفر {{ isDark ? 'باطل شده' : 'مصرف شده' }}</span
          >
        </div>
      </div>
      <div class="filters-wrapper" v-if="offerType">
        <filters-table
          v-if="offerType === 'typical'"
          :filters="offer.filters"
          :isDark="isDark"
        />
        <div :class="['single-item', isDark && 'single-item--dark']" v-else>
          این آفر به صورت تکی ایجاد شده است.
        </div>
      </div>
    </section>
    <failed-mode @refresh="getOffer" v-else />
    <modal-confirm
      :is-open="modals['confirm']"
      :is-loading="btnLoading"
      @modalconfirmclosed="modals['confirm'] = false"
      @modalconfirmcancelled="modals['confirm'] = false"
      @confirm="handleDisableOffer"
      text='آیا از "ابطال" این آفر مطمئن هستید؟'
    />
    <fixed-footer />
  </div>
</template>

<style scoped lang="scss">
$dark-text-color: #444;
$dark-bg-color-primary: #080808;
$dark-bg-color-secondary: #181818;

.offer__desc {
  max-width: 90%;
  text-align: justify;
  color: #777;
  font-size: 15px;
  line-height: 20px;
  padding: 10px;
  margin: 10px 0;
}

.wrapper--dark {
  background-color: $dark-bg-color-primary;
}
.wrapper--dark .bottom-part {
  background-color: $dark-bg-color-secondary;
  margin-top: 100px;
}
.wrapper--dark .customer-empty {
  background-color: $dark-bg-color-secondary;
  color: #333;
}

/** OFFER BLOCK */
.offer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.offer__title {
  width: calc(100% - 40px);
  padding: 0;
  margin: 10px 0 20px;
  position: relative;
  color: black;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.offer__info {
  background-color: #fff;
  width: 96%;
  border-radius: 20px 20px 0 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.offer__img {
  width: 100%;
  margin: 0;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
    filter: saturate(0.9);
  }
}
.offer__prices {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
}
.offer__pricePart {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.offer__price {
  position: relative;
  font-size: 22px;
  margin-bottom: 0;
  font-weight: bold;
  padding-top: 6px;
  &::after {
    content: 'R';
    font-size: 12px;
    top: -6px;
    left: 3px;
    position: relative;
  }
}
.price--old {
  color: #777;
  &::before {
    content: '';
    position: absolute;
    width: 120%;
    background-color: rgba(0, 0, 0, 0.4);
    height: 2px;
    top: 0;
    bottom: 0;
    left: -10%;
    margin: auto 0;
  }
}
.offer__vr {
  width: 1px;
  height: 60px;
  background-color: rgba(#000, 0.2);
}
.price--new {
  color: #c69d4e;
}
.offer__percent {
  color: #000;
  margin: 0 0 3px;
  font-size: 40px;
  position: relative;
  left: -7px;
  &::before {
    content: '';
    position: absolute;
    left: -15px;
    top: 0;
    bottom: 8px;
    margin: auto 0;
    width: 12px;
    height: 2px;
    background-color: #000;
  }
  &::after {
    content: '%';
    position: absolute;
    right: -32px;
    top: 0;
    bottom: 0px;
    margin: auto 0;
    // width: 12px;
    // height: 2px;
    // background-color: #000;
  }
}
.offer__off {
  margin: 10px 0 0;
  color: #aaa;
  font-weight: bold;
  font-size: 16px;
}
.offer__circles-col {
  z-index: 4;
  position: absolute;
  bottom: -7px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: auto;
}
.offer__circle {
  width: 14px;
  height: 14px;
  margin: 0 6px;
  border-radius: 100%;
  background-color: #eee;
}
// Dark modifier
.offer--dark {
  background-color: $dark-bg-color-primary;
}
.offer--dark .offer__img img {
  filter: saturate(0) opacity(0.6);
}
.offer--dark .offer__circle {
  background-color: $dark-bg-color-primary;
}
.offer--dark .offer__title {
  color: $dark-text-color;
}
.offer--dark .price--new {
  color: $dark-text-color;
}
.offer--dark .price--old {
  color: $dark-text-color;
  &::before {
    background-color: $dark-text-color;
  }
}
.offer--dark .offer__percent {
  color: $dark-text-color;
  &::before {
    background-color: $dark-text-color;
  }
}
.offer--dark .offer__off {
  color: $dark-text-color;
}
.offer--dark .offer__info {
  background-color: $dark-bg-color-secondary;
}
.offer--dark .offer__desc {
  color: $dark-text-color;
}
.offer--dark .offer__vr {
  background-color: #272727;
}

// Change in common css
.cmp-wrapper {
  padding-bottom: 75px;
}

.countdown {
  margin-top: 0;
  padding-top: 10px;
  width: 100%;
}

.tab {
  margin-bottom: 18px;
}

.tab--dark {
  background-color: #080808;
}

.load-pending--show {
  height: calc(100vh - 135px);
}
</style>
