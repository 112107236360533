import store from '@/store'
import {
  schemaMaker,
  validateForms,
  reqString,
  image as validImage,
  price as validPrice,
  priceLess,
  modalNumber
} from '@valid/JoiValidation'

// Get offer inputs and return errors (if any)
const validateOfferForm = ({
  offerName: name,
  offerDesc: desc,
  offerPrice: price,
  offerPriceNew: priceNew,
  offerTime: time,
  offerImage: image
}) => {
  // Define validation rules
  const isFormValid = schemaMaker({
    name: reqString('نام آفر'),
    image: validImage('تصویر آفر'),
    price: validPrice('قیمت اصلی', 0),
    priceNew: priceLess('قیمت جدید', price ? +price : 0),
    desc: reqString('توضیحات'),
    time: modalNumber('تاریخ اعتبار', 1)
  })

  // Validate inputs
  const errors = validateForms(
    { name, desc, image, price, priceNew, time },
    isFormValid
  )
  // Display errors
  if (errors && errors.length > 0) {
    for (let error of errors) {
      const message = {
        type: 'error',
        message: error
      }
      store.dispatch('addToast', message)
    }
  }

  return !!errors
}

export default () => ({ validateOfferForm })
