<script setup>
import { computed, onBeforeUnmount, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import Doughnut from '@common/Doughnut'

const store = useStore()
store.dispatch('fetchStat', { part: 'offer' })
const stat = computed(() => store.state.stat)

/******************
 * Doughnut Maker *
 ******************/
const chart = reactive({})
const bars = reactive([])

watch(stat, (stat) => {
  // Init doughnut
  const all = stat['active'] + stat['used'] + stat['expired']
  const activePercent = all === 0 ? 0 : Math.round((stat['active'] / all) * 100)
  const usedPercent = all === 0 ? 0 : Math.round((stat['used'] / all) * 100)
  const expiredPercent =
    all === 0 ? 0 : Math.round((stat['expired'] / all) * 100)

  chart['all'] = all
  chart['active'] = stat['active']
  chart['used'] = stat['used']
  chart['expired'] = stat['expired']
  chart['activePercent'] = activePercent
  chart['usedPercent'] = usedPercent
  chart['expiredPercent'] = expiredPercent
  chart['data'] = [stat['active'], stat['used'], stat['expired']]

  // Init Bars
  bars[2] = {
    id: 1,
    count: stat['expired'].toLocaleString(),
    width: expiredPercent + '%',
    color: '#333',
    title: 'تعداد آفر باطل شده'
  }
  bars[1] = {
    id: 2,
    count: stat['used'].toLocaleString(),
    width: usedPercent + '%',
    color: '#777',
    title: 'تعداد آفر مصرف شده'
  }
  bars[0] = {
    id: 3,
    count: stat['active'].toLocaleString(),
    width: activePercent + '%',
    color: '#c69d4e',
    title: 'تعداد آفر فعال'
  }
})

onBeforeUnmount(() => store.dispatch('clearStat'))
</script>

<template>
  <div class="stat-wrapper">
    <section class="load-resolved" v-if="stat">
      <div class="chartTitle">
        <h3 class="chartTitle__text">تعداد آفرهای ایجاد شده</h3>
        <h4 class="chartTitle__number">
          {{ chart['all']?.toLocaleString() }}
        </h4>
      </div>
      <div class="cmp-items-wrapper">
        <div class="chart">
          <doughnut
            :dataset="[
              chart['active'] == 0 ? 0.001 : chart['active'],
              chart['used'] == 0 ? 0.001 : chart['used'],
              chart['expired'] == 0 ? 0.001 : chart['expired']
            ]"
            :colors="['#c69d4e', '#777', '#333']"
            :size="150"
            :thickness="15"
            style="position:relative; bottom: 5px;"
          />
          <div class="chart__legend">
            <div class="chart__row">
              <span class="chart__value value--gold"
                >{{ chart['activePercent'] }}%</span
              >
              <h5 class="chart__title">آفر فعال</h5>
            </div>
            <div class="chart__row">
              <span class="chart__value value--silver"
                >{{ chart['usedPercent'] }}%</span
              >
              <h5 class="chart__title">آفر مصرف شده</h5>
            </div>
            <div class="chart__row">
              <span class="chart__value value--silver"
                >{{ chart['expiredPercent'] }}%</span
              >
              <h5 class="chart__title">آفر باطل شده</h5>
            </div>
          </div>
        </div>
        <div class="chart chart--hist">
          <div class="hist-wrapper">
            <div class="chart-row" v-for="bar in bars" :key="bar.id">
              <div class="chart-bar-row">
                <div class="chart-bar">
                  <i
                    :style="{
                      backgroundColor: bar['color'],
                      width: bar['width'] === '0%' ? '3px' : bar['width']
                    }"
                  />
                </div>
              </div>
              <div class="chart-row-stat">
                <h6>{{ bar['count'] }}</h6>
                <h5>{{ bar['title'] }}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="load-pending" v-else>
      <img src="@img/vipon-loading.svg" />
    </section>
  </div>
</template>

<style scoped>
.hist-wrapper .chart-row .chart-row-stat h6::after {
  content: '';
}

.chart--hist {
  margin-bottom: 0;
}
</style>
